import { ReactNode, createContext, useContext } from "react";

const initialState = {
  isLoggedIn: true,
  user: null,
};

interface Props {
  children?: ReactNode;
}

const authContext = createContext(initialState);

export const AuthProvider = ({ children }: Props) => {
  return (
    <authContext.Provider value={initialState}>{children}</authContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(authContext);
};
