import { McButton } from "@maersk-global/mds-react-wrapper/components-core/mc-button";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import OrderService from "../../../services/order.service";
import ToastNotification from "../../shared/ToastNotification";
const UploadOrder: React.FC = () => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const selectedFileRef = useRef<File | null>(null);
  const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [fileInputKey, setFileInputKey] = useState<string>(
    Date.now().toString()
  );

  const fileChangeListener = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (file) {
      setSelectedFile(file);
      selectedFileRef.current = file;
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
    selectedFileRef.current = null;
    setFileInputKey(Date.now().toString());
  };

  const uploadFileToScan = (file: any) => {
    setShowSuccessToast(false);
    setShowErrorToast(false);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          const base64 = reader.result.toString().split(",")[1];
          OrderService.sendToVotiro(base64, file?.name, "bdp").then(
            (response) => {
              setShowSuccessToast(true);
              setSelectedFile(null);
              selectedFileRef.current = null;
              setFileInputKey(Date.now().toString());
            },
            (error) => {
              setShowErrorToast(true);
              setSelectedFile(null);
              selectedFileRef.current = null;
              setFileInputKey(Date.now().toString());
            }
          );
        }
      };
    }
  };

  return (
    <div className="container">
      {showErrorToast && (
        <ToastNotification
          type="error"
          message="Something went wrong"
          duration={5000}
          toast={true}
        />
      )}
      {showSuccessToast && (
        <ToastNotification
          type="success"
          message="Order Sent Successfully"
          duration={5000}
          toast={true}
        />
      )}
      <div className="pt-2">
        <h2>Upload Order</h2>
      </div>

      <div className="d-flex pt-4">
        <div
          className="outer-div"
          title="If the selected file contains multiple orders, multiple orders will be created"
        >
          <input
            type="file"
            accept=".pdf"
            className="input-file-class"
            onChange={fileChangeListener}
            id="uploadFile"
            key={fileInputKey}
            data-testid="upload-file-input"
          />
          <div className="inner-div">
            <p className="m-0">
              {/* {t("dropFileHereLabel")} */}
              {"Drop file here"}
              <br /> {t("orLabel")}
            </p>
            <p className="m-0">
              <McButton label="" data-testid="choose-file">
                {/* t("selectFileLabel") */}
                {selectedFile ? selectedFile.name : "Select File"}
              </McButton>
            </p>
            <sub>({t("acceptedformatsLabel")}: .pdf)</sub>
          </div>
        </div>
      </div>
      <div className="text-end float-end mt-5">
        <McButton
          appearance="neutral"
          label="Cancel"
          data-testid="clickCancelButton"
          click={clearSelectedFile}
        >
          Cancel
        </McButton>
        <McButton
          appearance="primary"
          label="Create Order"
          className="ps-2 float-end"
          type="submit"
          data-testid="clickUploadButton"
          click={() => uploadFileToScan(selectedFileRef.current)}
          disabled={!selectedFileRef.current}
        >
          Create Order
        </McButton>
      </div>
    </div>
  );
};

export default UploadOrder;
