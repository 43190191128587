import logo from "../../../assets/img/logos/maeu.svg";

export const logoConfig = {
  id: "logo",
  href: "/create-order",
  img: {
    alt: "Maersk logo",
    src: logo,
    width: 150,
    mobileWidth: 80,
    push: "m",
  },
};
