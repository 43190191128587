import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import Navigation from "./component/Layout/Navigation/Navigation";
import UploadOrder from "./component/Order/UploadOrder/UploadOrder";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./routes/ProtectedRoute";
import { getCookie } from "./utils/cookie";

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const langLocale: any = getCookie("selectedLang") || "en";
    i18n.changeLanguage(langLocale);
  }, [i18n]);

  return (
    <div className="main-content mds">
      <div className="container">
        <BrowserRouter>
          <AuthProvider>
            <Navigation />

            <Routes>
              {/* <Route
                path="/create-order"
                element={
                  <ProtectedRoute>
                    <OrderInput />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path="/upload-order"
                element={
                  <ProtectedRoute>
                    <UploadOrder />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={<Navigate to="/upload-order" replace />}
              />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </div>
    </div>
  );
};
export default App;
