import { McButton } from "@maersk-global/mds-react-wrapper/components-core/mc-button";
import { McModal } from "@maersk-global/mds-react-wrapper/components-core/mc-modal";
import { McNotification } from "@maersk-global/mds-react-wrapper/components-core/mc-notification";
import { McToast } from "@maersk-global/mds-react-wrapper/components-core/mc-toast";
import React, { useRef } from "react";

interface ToastNotificationProps {
  // type?: 'success' | 'error' | 'warning' | 'info' | 'neutral';
  type?: any;
  message?: string;
  duration?: number;
  toast?: boolean;
}
// type NotificationAppearance = 'success' | 'error' | 'warning' | 'info' | 'neutral';

const ToastNotification: React.FC<ToastNotificationProps> = ({
  type = "success",
  message = "",
  duration = 5000,
  toast = "true",
}) => {
  const toastRef = useRef<any>(true);
  const popUpRef = useRef<any>(true);
  React.useEffect(() => {
    setTimeout(() => {
      if (toast) {
        toastRef?.current?.show();
      } else {
        popUpRef.current?.show();
      }
    }, 1000);
  }, [message, type, duration, toast]);

  return (
    <div>
      {toast && (
        <McToast
          ref={toastRef}
          position="top-right"
          appearance={type}
          duration={duration}
        >
          <McNotification
            icon={type === "success" ? "check-circle" : "times-circle"}
            body={message}
          ></McNotification>
        </McToast>
      )}

      {!toast && (
        <McModal ref={popUpRef} dimension="small" heading="Heading">
          <span className="mds-text--medium-normal">
            Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
            In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
          </span>
          <McButton slot="primaryAction" appearance="primary" dialogaction="ok">
            OK
          </McButton>
          <McButton
            slot="secondaryAction"
            appearance="neutral"
            variant="outlined"
            dialogaction="cancel"
          >
            Cancel
          </McButton>
        </McModal>
      )}
    </div>
  );
};
export default ToastNotification;
