import { isCustomerLoggedIn, redirectToLogin } from "@maersk-global/shared-js";
import axios from "axios";

const apiUrl = "https://api-cdt.maersk.com/oauth2/access_token";

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

export const login = (successUrl: string) => {
  redirectToLogin(successUrl);
};

export const isLoggedIn = () => {
  return isCustomerLoggedIn();
};

export const getAccessToken = async () => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const data = new URLSearchParams();
  data.set("grant_type", "client_credentials");
  data.set("client_id", "wMl1TiZGUgI70BhnhowbZ934IXAil8vQ");
  data.set("client_secret", "5Hrr6nv4Kao58Yy3");
  data.set("scope", "openid dsgnorderworkflow.write");
  return axios.post(apiUrl, data, { headers });
};
