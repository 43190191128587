export const footerNav: any = {
  en: {
    copyright: "@A.P. Moller - Maersk",
    columns: [
      {
        links: [
          {
            title: "About us",
            href: "#",
          },
          {
            title: "Career",
            href: "#",
          },
        ],
      },
      {
        links: [
          {
            title: "Contact us",
            href: "#",
          },
          {
            title: "Press",
            href: "#",
          },
        ],
      },
      {
        links: [
          {
            title: "Terms & conditions",
            href: "https://terms.maersk.com/",
            target: "_blank",
          },
          {
            title: "Privacy policy",
            href: "https://www.maersk.com/privacy-policy",
            target: "_blank",
          },
        ],
      },
      {
        links: [
          {
            title: "Cookie policy",
            href: "https://www.maersk.com/cookie-policy",
            target: "_blank",
          },
          {
            title: "Cookie preferences",
            href: "/cookie-policy",
          },
        ],
      },
    ],
    social: {
      links: [
        {
          title: "Facebook",
          href: "https://www.facebook.com/Maersk",
          target: "_blank",
        },
        {
          title: "LinkedIn",
          href: "https://www.linkedin.com/company/maersk-group/",
          target: "_blank",
        },
        {
          title: "Twitter",
          href: "https://twitter.com/Maersk",
          target: "_blank",
        },
        {
          title: "Instagram",
          href: "https://www.instagram.com/maersk_official/",
          target: "_blank",
        },
        {
          title: "YouTube",
          href: "https://www.youtube.com/channel/UCZiaSUekMl9qG7FBn4rNZ9Q",
          target: "_blank",
        },
      ],
    },
  },
  es: {
    copyright: "@A.P. Moller - Maersk",
    columns: [
      {
        links: [
          {
            title: "Sobre nosotras",
            href: "#",
          },
          {
            title: "Carrera",
            href: "#",
          },
        ],
      },
      {
        links: [
          {
            title: "Contacta con nosotras",
            href: "#",
          },
          {
            title: "Prensa",
            href: "#",
          },
        ],
      },
      {
        links: [
          {
            title: "Términos y condiciones",
            href: "https://terms.maersk.com/",
            target: "_blank",
          },
          {
            title: "Política de privacidad",
            href: "https://www.maersk.com/privacy-policy",
            target: "_blank",
          },
        ],
      },
      {
        links: [
          {
            title: "Política de cookies",
            href: "https://www.maersk.com/cookie-policy",
            target: "_blank",
          },
          {
            title: "Preferencias de cookies",
            href: "/cookie-policy",
          },
        ],
      },
    ],
    social: {
      links: [
        {
          title: "Facebook",
          href: "https://www.facebook.com/Maersk",
          target: "_blank",
        },
        {
          title: "LinkedIn",
          href: "https://www.linkedin.com/company/maersk-group/",
          target: "_blank",
        },
        {
          title: "Twitter",
          href: "https://twitter.com/Maersk",
          target: "_blank",
        },
        {
          title: "Instagram",
          href: "https://www.instagram.com/maersk_official/",
          target: "_blank",
        },
        {
          title: "YouTube",
          href: "https://www.youtube.com/channel/UCZiaSUekMl9qG7FBn4rNZ9Q",
          target: "_blank",
        },
      ],
    },
  },
};
