export const headerNav: any = {
  en: {
    primary: {
      links: [
        {
          title: "Upload Order",
          type: "link",
          href: "/upload-order",
        },
        {
          type: "language",
          href: "#",
          submenu: {
            align: "right",
            links: [
              {
                title: "English",
                dataId: "en",
              },
              {
                title: "Español",
                dataId: "es",
              },
            ],
          },
          push: "l",
        },
      ],
    },
  },
  es: {
    primary: {
      links: [
        {
          title: "Subir pedido",
          type: "link",
          href: "/upload-order",
        },
        {
          type: "language",
          href: "#",
          submenu: {
            align: "right",
            links: [
              {
                title: "English",
                dataId: "en",
              },
              {
                title: "Español",
                dataId: "es",
              },
            ],
          },
          push: "l",
        },
      ],
    },
  },
};
