import { useEffect } from "react";
import { getCookie } from "../../../utils/cookie";
import { footerNav } from "./Footer.const";
import { headerNav } from "./Header.const";
import { logoConfig } from "./Logo.const";

const Navigation: React.FC = () => {
  useEffect(() => {
    const script: any = document.createElement("script");
    script.src =
      "https://assets.maerskline.com/ui-sln/green/1.1.1-0/sln.maeu.1.1.1-0.js";
    document.body.appendChild(script);
    script.onload = () => {
      const langLocale: any = getCookie("selectedLang") || "en";
      const nativeWindow: any = window as any;
      nativeWindow.slnConfig = {
        logo: logoConfig,
        header: headerNav[langLocale],
        footer: footerNav[langLocale],
      };
      nativeWindow.sln.reload();
    };
  }, []);
  return <div></div>;
};
export default Navigation;
