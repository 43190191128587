import axios from "axios";
import { config } from "../config";

const OrderService = {
  sendToVotiro: (
    base64: string,
    fileName: any,
    customerName: string
  ): Promise<any> => {
    const payload = {
      content: base64,
      fileName: fileName,
      sentBy: "UI",
      customerName: customerName,
    };

    return axios.post<any>(
      `${config.AZ_FUNCTION_URL}/scanAndUploadAttachmentToBlob`,
      payload
    );
  },
};

export default OrderService;
